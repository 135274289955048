
export default {
  name: 'direction-image-card',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
