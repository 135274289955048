
export default {
  name: 'section-directions',
  data () {
    return {
      list: [
        {
          from: 'TJM',
          to: 'NUX',
          price: '8 000',
        },
        {
          from: 'SLY',
          to: 'RGK',
          price: '10 990',
        },
        {
          from: 'NOJ',
          to: 'LED',
          price: '10 900',
        },
        {
          from: 'NYM',
          to: 'SVX',
          price: '8 860',
        },
      ],
    };
  },
};
