
export default {
  name: 'section-news',
  props: {
    news: {
      type: Object,
      required: true,
    },
  },
};
