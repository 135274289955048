
export default {
  name: 'news-card',
  props: {
    item: {
      type:Object,
      required: true,
    },
  },
};
