import { render, staticRenderFns } from "./index.vue?vue&type=template&id=864b8dca&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=864b8dca&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionMain: require('/opt/yamal.ru/src/components/section/main.vue').default,SectionDirections: require('/opt/yamal.ru/src/components/section/directions.vue').default,SectionNews: require('/opt/yamal.ru/src/components/section/news.vue').default,BaseFooter: require('/opt/yamal.ru/src/components/base/footer.vue').default})
