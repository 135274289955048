
import { mapGetters } from 'vuex';

export default {
  name: 'base-footer',
  props: {
    compact: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters('sulu', ['menu']),
    isSale() {
      return this.$route.name === 'sale';
    },
    isLinks() {
      return !this.compact && !this.isMobile;
    },
  },
};
