
import { getSuluPage, fillSeo, getSuluApi, handleResponse} from 'razlet-sulu-sdk/lib/utils/helpers';
import Sulu from 'razlet-sulu-sdk/lib/entities/factory';

export default {
  name: 'index-page',
  layout: 'index',
  async asyncData ({ store, route, error, redirect}) {
    const results = await Promise.allSettled([
      getSuluPage({ store, route, error, redirect }),
      getSuluApi({ store, error, redirect, path: `news-list` }),
    ]);

    const [result, news] = handleResponse(results, error);
    return {result, news};
  },
  data() {
    return {
      news: {},
      result: {},
      loading: true,
    };
  },
  created() {
    this.fillSulu(this.result, this.news);
  },
  mounted() {
    this.$bus.$on('locale-changed', () => {
      this.loading = true;
      window.scrollTo(0, 0);
      this.$nuxt.refresh().then(() => {
        this.fillSulu(this.result, this.news);
      });
    });
    this.checkAffPath();
  },
  destroyed() {
    this.$bus.$off('locale-changed');
  },
  methods: {
    checkAffPath() {
      const affPath = window.location.hash;

      if (affPath && affPath.length > 3) {
        const route = affPath.substring(2, affPath.length);
        this.goToAffSeparatePage(route);
      }
    },
    goToAffSeparatePage(route) {
      window.location.href = `${window.location.origin}/sale#/${route}`;
    },
    fillSulu(res, news) {
      const suluFactory = new Sulu();
      this.result = suluFactory.create(res);
      this.news = suluFactory.create(news);
      this.loading = false;
    },
  },
  head() {
    return {
      ...fillSeo(this, this.result.seo),
    };
  },
};
